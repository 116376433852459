@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;300;400;500;700;900&display=swap');

:root {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  margin: 0;
  padding: 0;

  --background-color: #121212;
  --surface-color: #344955;
  --surface-02-color: #4A6572;
  --surface-03-color: #4A6572;
  --secondary-color: #F9AA33;
  --error-color: #cf6679;

  --on-background-color: #FFFFFF;
  --on-surface-color: #FFFFFF;
  --on-surface-02-color: #FFFFFF;
  --on-surface-03-color: #FFFFFF;
  --on-secondary-color: #000000;
  --on-error-color: #000000;

  --padding: 4px;
  --margin: 4px;

  --border: 1px;;

  --screen-01: 10rem;

}


html {
  background-color: var(--background-color);
  color: var(--on-background-color);
  font-size: 18px;
}

body {
  font-family: 'Roboto Mono', monospace;
}

p {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

main {
  display: grid;
  grid-template-columns: auto;
  grid-template-areas: "bar" "content";
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
}

@media screen and (min-width: 40rem) {
  main {
    grid-template-columns: 1.5rem auto;
    grid-template-areas: "bar content";
  }
}

.content {
  grid-area: content;
}

.actionbar {
  grid-area: bar;
  display: flex;
  writing-mode: horizontal-tb;
  flex-direction: row;
  justify-content: flex-end;
  align-content: flex-start;
  text-transform: uppercase;
}

@media screen and (min-width: 40rem) {
  .actionbar {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    line-height: 1rem;
  }
}

.action {
  border-radius: 3px;
  /*padding: var(--padding);*/
  background-color: var(--surface-color);
  color: var(--on-surface-color);
  cursor: pointer;
  transition: all 200ms ease-in;
  font-weight: bold;
}

.action:hover {
  background-color: var(--secondary-color);
  color: var(--on-secondary-color);
}

.action-secondary {
  border-radius: 3px;
  padding: var(--padding);
  background-color: var(--secondary-color);
  color: var(--on-secondary-color);
  cursor: pointer;
  transition: all 200ms ease-in;
}

.action-secondary:hover {
  background-color: var(--on-secondary-color);
  color: var(--secondary-color);
}

.error {
  padding: 3px;
  background-color: var(--error-color);
  color: var(--on-error-color);
  cursor: pointer;
  font-weight: normal;
}

/*****  ZONE *****/
.zone {
  width: 100%;
  padding: var(--padding);
}

.zone-title {
  padding: var(--padding);
  margin-bottom: 0.5rem;
  background-color: var(--surface-02-color);
  color: var(--on-surface-02-color);
}

@media screen and (min-width: 40rem) {
  .zone {
    margin-bottom: 1rem;
  }
}
/**** FLEX ****/
.columns {
  flex-direction: column;
  flex-wrap: wrap;
}

.rows {
  flex-direction: row;
  flex-wrap: wrap;
}
/*****  PANEL *****/

.panel {
  width: 100%;
  border: 0;
}

.panel-title {
  background-color: var(--surface-03-color);
  color: var(--on-surface-03-color);
  padding: var(--padding);
  margin-bottom: 0.5rem;
}

.panel-subtitle {
  width: 100%;
  padding: var(--padding);
}

.panel-content {
  padding: var(--padding);
  display: flex;
}

@media screen and (min-width: 40rem) {
  .panel {
    border-left-width: var(--border);
    margin-bottom: 1rem;
  }

  .panel-title {

  }

  .panel-subtitle {
  }

  .panel-content {
  }

}

/*****  WIDGET *****/
.widget {
  width: 100%;
  border: 0;
  border-style: dotted;
  border-bottom-width: var(--border);
  padding: var(--padding);
}

.widget-title {
  font-weight: bolder;
  padding: var(--padding);
  margin-bottom: 0.5rem;
}

.widget-subtitle {
  width: 100%;
  padding: var(--padding);
  font-weight: normal;
}

.widget-content {
  padding: var(--padding);
}

.widget dl {
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.widget dt {
  float: left;
  width: 30%;
  clear: left;
  padding: 0;
  margin: 0;
  word-break: break-all;
}

.widget dd {
  float: left;
  width: 70%;
  padding: 0;
  margin: 0;
  word-break: break-all;
}

.widget-topics {
  display: flex;
  font-weight: lighter;
}

.widget-topic {
  padding-right: 1rem;
}

@media screen and (min-width: 40rem) {
  .widget {
    width: 100%;
    border-left-width: var(--border);
    margin-bottom: 1rem;
  }

  .widget-title {

  }

  .widget-subtitle {
  }

  .widget-content {
  }

  .widget dl {
    width: 100%;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }

  .widget dt {
    float: left;
    width: 30%;
    clear: left;
    padding: 0;
    margin: 0;
  }

  .widget dd {
    float: left;
    width: 70%;
    padding: 0;
    margin: 0;
  }

}

.message {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-row-gap: 1rem;
  grid-template-areas: "header" "content" "image" "footer";
  justify-items: left;
  align-items: stretch;
}

.message:last-child {
  padding-bottom: var(--padding);
}

.message-header {
  grid-area: header;
  padding: var(--padding);
  border-left-width: 2px;
  border-left-style: solid;
}

.message-title {
  font-weight: bolder;
}

.message-subtitle {
  font-weight: normal;
}

.message-content {
  grid-area: content;
  padding: var(--padding);
}


@media screen and (min-width: 40rem) {

  .message {
    grid-row-gap: 1rem;
  }
}


td.number {
  text-align: right;
}

.telemetryActualList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr;
  grid-column-gap: 1rem;

}

.right {
  text-align: right;
}

